import { all, fork } from 'redux-saga/effects';
import { getProducts, watchProductsSaga } from '../sagas/home.saga';

const sagas = [getProducts, watchProductsSaga];

/**
 * combines all sagas into one root saga
 */
export default function* rootSaga() {
    yield all(sagas.map((saga) => fork(saga)));
}
