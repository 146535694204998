import { put, takeLatest } from 'redux-saga/effects';
import {
    fetchedProducts,
    fetchedProductsError,
    FETCH_PRODUCTS
} from '../actions/home.action';

import { pulseliveSSO } from '@pulselive/keycloak-sdk-wrapper';

/**
 * Get products saga
 */
export function* getProducts() {
    try {
        // We know at this point pulseliveSSO is initiated as the app only starts once it is.
        const applications = pulseliveSSO.config.applications;

        yield put(fetchedProducts(applications));
    } catch (error) {
        yield put(fetchedProductsError(error));
    }
}

/**
 * Watch products saga
 */
export function* watchProductsSaga() {
    yield takeLatest(FETCH_PRODUCTS, getProducts);
}
