import React from 'react';
import styles from './scss/home.module.scss';
import Tile from './tile.component';
import connect from './home.connect';
import PropTypes from 'prop-types';

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
function Home(props) {
    const maxCols = 4;

    const filterTiles = props.products.filter((product) => {
        return product.name !== 'portal';
    });

    const appNum = filterTiles.length;
    const colNum = appNum > maxCols ? maxCols : appNum;

    const tiles = filterTiles.map((product) => {
        return [
            <Tile
                colSize={colNum}
                {...product}
                maxCols={maxCols}
                key={product.name}
            />
        ];
    });

    return (
        <section className={styles.portal}>
            <div className={styles.portalContainer}>
                <h1 data-cy="home-heading" className={styles.portalTitle}>
                    Your Products
                </h1>

                <ul data-cy="home-menu" className={styles.portalNav}>
                    {tiles}
                </ul>
            </div>
        </section>
    );
}

Home.propTypes = {
    /*
     * List of products
     */
    products: PropTypes.array
};

export default connect(Home);
