export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCHED_PRODUCTS = 'FETCHED_PRODUCTS';
export const FETCHED_PRODUCTS_ERROR = 'FETCHED_DESTINATIONS_ERROR';

export const fetchProducts = () => ({ type: FETCH_PRODUCTS });
export const fetchedProducts = (data) => ({ type: FETCHED_PRODUCTS, data });
export const fetchedProductsError = (error) => ({
    type: FETCHED_PRODUCTS_ERROR,
    error
});
