import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root.component';
import singleSpaReact from 'single-spa-react';

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    // eslint-disable-next-line no-unused-vars
    errorBoundary(err, info, props) {
        return err;
    }
});

export const bootstrap = lifecycles.bootstrap;
export const mount = lifecycles.mount;
export const unmount = lifecycles.unmount;
