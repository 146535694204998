import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchProducts } from '../../state/actions/home.action';

const mapStateToProps = (state) => ({ products: [...state.fetchedProducts] });

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchProducts
        },
        dispatch
    );

const connectListingView = (component) =>
    connect(mapStateToProps, mapDispatchToProps)(component);

export default connectListingView;
