import './set-public-path';
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from '../state/store';
import Home from './components/home.component';
import { CookieBanner } from '@pulselive/core-product-components';

import { HeadProvider, Link } from 'react-head';

const history = createBrowserHistory();
window.tgHistory = history;

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
export default function Root(props) {
    const getEnvironment = () => {
        const match = window.location.host.match(/(custom|dev|local|test)/i);

        if (!match) {
            // component is being used on production
            return 'prod';
        }
        return match.shift();
    };

    const getFavicon = () => {
        return `favicon-${getEnvironment()}.svg`;
    };

    return (
        <HeadProvider>
            <Link rel="icon" href={`/${getFavicon()}`} />
            <BrowserRouter>
                <Provider store={store}>
                    <Route
                        path="/"
                        exact
                        render={() => <Home routes={props.applicationRoutes} />}
                    />
                </Provider>
            </BrowserRouter>
            <CookieBanner />
        </HeadProvider>
    );
}

// type validation
Root.propTypes = {
    /**
     * Array of objects used to construct the nav items
     */
    applicationRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            pathname: PropTypes.string
        })
    )
};
