import React from 'react';
import styles from './scss/tile.module.scss';
import PropTypes from 'prop-types';

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
export default function Tile(props) {
    const { label, description, logoUrl, url, colSize, maxCols } = props;
    const colClass = styles[`col${colSize}`];
    const colWideClass = maxCols === colSize ? styles.col3Wide : '';

    // TODO: replace defaultLogo with dynamic URL from API when available through props
    return (
        <li
            className={`${colClass} ${colWideClass} ${styles.col2Tab} ${styles.col1Phab} ${styles.tileCol}`}
        >
            <a href={url} className={styles.tile}>
                <img src={logoUrl} width="40" height="40"></img>
                <h2 className={styles.tileName}>{label}</h2>
                <p className={styles.tileDescription}>{description}</p>
            </a>
        </li>
    );
}

Tile.propTypes = {
    /*
     * Product name, tile title
     */
    label: PropTypes.string,
    /*
     * Short description of what the product does
     */
    description: PropTypes.string,
    /*
     * CTA for tile
     */
    url: PropTypes.string,
    /*
     * url to tile svg
     */
    logoUrl: PropTypes.string,
    /*
     * number of columns to be shown in grid
     */
    colSize: PropTypes.number,
    /*
     * max number of columns to be shown in grid
     */
    maxCols: PropTypes.number
};
