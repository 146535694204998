import produce from 'immer';
import { FETCH_PRODUCTS, FETCHED_PRODUCTS } from '../actions/home.action';

const initialState = {
    loading: false,
    fetchedProducts: []
};

export default produce((draft, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case FETCH_PRODUCTS:
            draft.loading = true;
            break;
        case FETCHED_PRODUCTS:
            draft.loading = false;
            draft.fetchedProducts = action.data;
            break;
    }
}, initialState);
